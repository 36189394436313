<template>
  <div>
    <!-- 电脑端 -->
    <div class="fa">
      <div class="pass" v-if="isShow">
        <p>房间密码</p>
        <input type="text" placeholder="请输入密码" v-model="pass" name id />
        <div class="btn" @click="passbtn"></div>
      </div>
      <div class="pc">
        <div class="rollmain">
          <!-- 返回按钮 -->
          <div>
            <div class="close" @click="close">
              <img width="90px" src="@/assets/roll/closs.png" alt />
            </div>
          </div>
          <!-- 活动详情 -->
          <div class="info color-white f-13">
            活动倒计时
            <div class="time">
              <span>{{ day }}</span> 天 <span>{{ hour }}</span> 小时
              <span>{{ minute }}</span> 分钟
            </div>
            <div class="name">{{ rollname }}</div>
            <div class="rollInfo f-13">玩法规则：{{ rollinfo }}</div>
            <div class="count">
              已有
              <span>{{ current }}/{{ countAll }}</span
              >人参加
            </div>
          </div>
          <div
            class="d-flex"
            style="align-items: flex-end; justify-content: center"
          >
            <div class="title" @click="switchs">
              <img
                v-if="istitleType == 'people'"
                src="@/assets/roll/titles.png"
                alt
              />
              <img
                v-if="istitleType == 'box'"
                src="@/assets/roll/title.png"
                alt
              />
            </div>
            <div class="title" @click="switchs">
              <img
                v-if="istitleType == 'box'"
                src="@/assets/roll/peopletitles.png"
                alt
              />
              <img
                v-if="istitleType == 'people'"
                src="@/assets/roll/peopletitle.png"
                alt
              />
            </div>
          </div>
          <!-- 奖品和人数 -->
          <div v-if="istitleType == 'box'">
            <div class="gifts d-flex">
              <div
                class="item"
                v-for="(item, index) in rollOrnaments"
                :key="index"
              >
                <div class="giftItem t-center color-white f-13">
                  <div class="price d-flex" style="justify-content: center">
                    <div class="w-money"></div>
                    <div class="priceItem">{{ item.price }}</div>
                  </div>
                  <div class="img">
                    <img :src="item.oimg" alt />
                  </div>
                  <div class="g-title">{{ item.oname }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 人数 -->
          <div v-if="istitleType == 'people'">
            <div class="gifts d-flex">
              <div class="item" v-for="(item, index) in users" :key="index">
                <img
                  v-if="item.portrait == null"
                  src="@/assets/images/user/head.jpg"
                  width="50px"
                  height="50px"
                  style="
                    border-radius: 50%;
                    margin-right: 10px;
                    margin-bottom: 10px;
                  "
                  alt=""
                />
                <img
                  v-else
                  :src="item.portrait"
                  width="50px"
                  height="50px"
                  style="
                    border-radius: 50%;
                    margin-right: 10px;
                    margin-bottom: 10px;
                  "
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="add" @click="AddRollHome">立即参加</div>
        <div v-if="isShow" class="bg"></div>
      </div>
    </div>
    <!-- 手机端 -->
    <div class="fas">
      <div class="pass" v-if="isShow">
        <p>房间密码</p>
        <input type="text" placeholder="请输入密码" v-model="pass" name id />
        <div class="btn" @click="passbtn"></div>
      </div>
      <div class="phone">
        <!-- 返回按钮 -->
        <div>
          <div class="closes d-flex" @click="close">
            <img src="@/assets/roll/phone/close.png" alt="" /> 返回上一级
          </div>
        </div>
        <div class="rollmain">
          <!-- 活动详情 -->
          <div class="homeabout">活动详情</div>
          <!-- 活动详情 -->
          <div class="info color-white f-13">
            <div class="time">
              <span>{{ day }}</span> 天
              <img src="@/assets/roll/phone/gun.png" alt="" /><span>{{
                hour
              }}</span>
              时 <img src="@/assets/roll/phone/gun.png" alt="" />
              <span>{{ minute }}</span> 分钟
            </div>
            <div class="name zt">{{ rollname }}</div>
            <div class="rollInfo f-13">玩法规则：{{ rollinfo }}</div>
            <!-- <div class="count">
              已有
              <span>{{ current }}/{{ countAll }}</span
              >人参加
            </div> -->
          </div>
          <div class="add" @click="AddRollHome">立即参加</div>
          <!-- 奖品和人数 -->
          <div>
            <div class="title d-flex">
              <!-- istitleType:'box' -->
              <!--  -->
              <!-- class="activetitle" -->
              <div
                @click="switchs"
                :class="`${istitleType === 'box' ? 'activetitle' : ' '}`"
              >
                奖池详情
              </div>
              <div
                @click="switchs"
                :class="`${istitleType === 'people' ? 'activetitle' : ' '}`"
              >
                参与人数
              </div>
            </div>
            <!-- 奖池详情 -->
            <div style="margin-bottom: 50px" v-if="istitleType == 'box'">
              <!-- 总数 -->
              <div class="allnum d-flex">
                <div>饰品：{{ count }}</div>
                <div>
                  总价值：<span>{{ allPrice }}</span>
                </div>
              </div>
              <div class="gifts d-flex">
                <div
                  class="item"
                  v-for="(item, index) in rollOrnaments"
                  :key="index"
                >
                  <div class="giftItem t-center color-white f-13">
                    <div class="img">
                      <img :src="item.oimg" alt />
                    </div>
                    <div class="g-title">{{ item.oname }}</div>
                    <div class="price d-flex" style="justify-content: center">
                      <div class="w-money"></div>
                      <div class="priceItem">{{ item.price }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 参与人数 -->
            <div style="margin-bottom: 50px" v-if="istitleType == 'people'">
              <!-- 总数 -->
              <div class="allnum d-flex">
                <div>人数：{{ current }}</div>
                <div>
                  总价值：<span>{{ allPrice }}</span>
                </div>
              </div>
              <!-- 头像 -->
              <div class="headbox d-flex">
                <div
                  class="peopleIiem"
                  v-for="(item, index) in users"
                  :key="index"
                >
                  <img
                    v-if="item.portrait == null"
                    src="@/assets/images/user/head.jpg"
                    width="50px"
                    height="50px"
                    style="
                      border-radius: 50%;
                      margin-right: 10px;
                      margin-bottom: 10px;
                    "
                    alt=""
                  />
                  <img
                    v-else
                    :src="item.portrait"
                    width="50px"
                    height="50px"
                    style="
                      border-radius: 50%;
                      margin-right: 10px;
                      margin-bottom: 10px;
                    "
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isShow" class="bg"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getROllActive, getRoomsJoin } from "../../api/index";
export default {
  inject: ["reload"],
  name: "active",
  data() {
    return {
      pass: "",
      rollId: "",
      btnActive: "gifts",
      isShow: false,
      roll: {},
      rollOrnaments: [],
      users: [],
      allPrice: 0,
      startTime: "",
      time: "",
      rollname: "",
      rollinfo: "",
      countAll: "",
      current: "",
      day: "",
      hour: "",
      minute: "",
      count: 0,
      istitleType: "box",
    };
  },
  methods: {
    switchs() {
      if (this.istitleType == "box") {
        this.istitleType = "people";
      } else {
        this.istitleType = "box";
      }
    },
    passbtn() {
      if (this.pass == "") {
        this.$message.error("请输入密码!");
      }
      getRoomsJoin(this.rollId, this.pass).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("加入成功！");
          this.isShow = false;
        } else {
          this.$message.error(res.data.msg);
          this.isShow = false;
        }
      });
    },
    close() {
      this.$router.go(-1);
    },
    //加入房间
    AddRollHome() {
      getROllActive(this.rollId).then((res) => {
        if (res.data.data.isPass) {
          this.isShow = res.data.data.isPass;
        } else {
          this.isShow = false;
          getRoomsJoin(this.rollId).then((res) => {
            let code = res.data.code;
            if (code == 200) {
              this.$message.success(res.data.msg);
              this.reload();
            } else if (code == 500) {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    rollBtn(state) {
      this.btnActive = state;
      if (this.btnActive == "people") {
        this.isshow = false;
      } else {
        this.isshow = true;
      }
    },
  },
  mounted() {
    //获取到路由穿过来的id
    this.rollId = this.$route.params.rollId;
    getROllActive(this.rollId).then((res) => {
      let rolldata = res.data.data;
      this.count = res.data.data.count;
      this.allPrice = rolldata.allPrice;
      this.roll = rolldata.roll;
      this.rollOrnaments = rolldata.rollOrnaments;
      this.users = rolldata.users;
      this.startTime = rolldata.roll.startTime;
      this.rollname = rolldata.roll.name;
      this.rollinfo = rolldata.roll.rollInfo;
      this.countAll = rolldata.perLimitFormat;
      this.current = rolldata.userCount;
      function compare(property) {
        return function (obj1, obj2) {
          var value1 = obj1[property];
          var value2 = obj2[property];
          return value2 - value1; // 降序
        };
      }
      this.rollOrnaments.sort(compare("price"));
      var endDate = this.startTime;
      endDate = endDate.replace(/-/g, "/");
      var DateM = new Date(endDate).getTime(); //得到毫秒数
      var time = new Date().getTime();
      this.time = DateM - time;
      this.day = parseInt(this.time / (1000 * 60 * 60 * 24));
      this.hour = parseInt(
        (this.time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minute = parseInt((this.time % (1000 * 60 * 60)) / (1000 * 60));
    });
  },
};
</script>

<style scoped lang="scss">
.fa {
  padding-top: 30px;
  box-sizing: border-box;
  width: 1131px;
  height: 460px;
  margin: 30px auto 0;
  background: url("~@/assets/images/home/mainallbg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .pass {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 50%;
    bottom: 0;
    margin: auto;
    transform: translateX(-50%);
    width: 380px;
    height: 180px;
    background: url("~@/assets/roll/pass.png") no-repeat;
    background-size: 100% 100%;
    padding: 30px 100px;
    box-sizing: border-box;
    p {
      color: #eee;
      font-size: 20px;
      text-align: center;
      margin: 15px auto;
    }
    input {
      background: none;
      border: 1px solid rgb(51, 146, 255);
      border-radius: 15px;
      height: 30px;
      outline: none;
      color: #eee;
      line-height: 30px;
      font-size: 16px;
      text-align: center;
      margin-bottom: 15px;
    }
    .btn {
      margin: auto;
      width: 90px;
      height: 36px;
      background: url("~@/assets/roll/btn.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .bg {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 9%;
    z-index: 90;
    background-color: rgba(0, 0, 0, 0.726);
  }
  .add {
    position: absolute;
    bottom: 30px;
    font-size: 25px;
    color: #fff;
    left: 50%;
    transform: translateX(-50%);
    width: 36%;
    text-align: center;
  }
  .t-center {
    text-align: center;
  }
  .pc {
    .rollmain {
      height: 295px;
      overflow: auto;
      margin-top: 50px;
      .info {
        margin-top: 5px;
        .name {
          font-size: 35px;
          color: #37d8ff;
          font-style: italic;
        }
        .time {
          font-size: 13px;
          color: #eee;
          margin: 15px auto;
          span {
            font-size: 38px;
            font-weight: none;
            color: gold;
            margin: 0 10px;
          }
        }
        .rollInfo {
          color: #629dc7;
          margin: 15px auto;
        }
        .count {
          margin: 15px auto;
          span {
            width: 318px;
            height: 41px;
            font-size: 30px;
            background: url("~@/assets/roll/pers.png") no-repeat;
            background-size: 100% 100%;
            letter-spacing: 21px;
            padding: 0 10px;
            display: inline-block;
          }
        }
      }
    }
    .info {
      text-align: center;
    }
    .g-pNAV {
      margin: 25px auto;
      width: 70%;
      justify-content: space-between;
    }
    .item {
      margin-bottom: 28px;
    }
    .gifts {
      margin: auto;
      width: 990px;
      box-sizing: border-box;
      padding: 0px 20px 20px 20px;
      flex-wrap: wrap;
      overflow: auto;
      position: relative;
      .title {
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .giftItem {
      width: 153px;
      height: 115px;
      background: url("~@/assets/roll/gifts.png") no-repeat;
      background-size: 100% 100%;
      padding: 12px;
      box-sizing: border-box;
      .g-title {
        margin-top: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        // 没置超出部分显示省略号
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
      .img img {
        width: 100px;
        height: 68px;
      }
      .w-money {
        width: 17px;
        height: 17px;
        background: url("~@/assets/images/shopping/bean.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .peopleIiem .p-img img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
    }
    .close {
      position: absolute;
      top: 40px;
      right: 85px;
    }
  }
}
.fas {
  display: none;
}
@media (max-width: 550px) {
  .fa {
    display: none;
  }
  .fas {
    padding-top: 0px;
    box-sizing: border-box;
    width: 100%;
    background: none;
    display: block !important;
    .pass {
      z-index: 999;
      position: fixed;
      top: 0;
      left: 50%;
      bottom: 0;
      margin: auto;
      transform: translateX(-50%);
      width: 380px;
      height: 180px;
      background: url("~@/assets/roll/pass.png") no-repeat;
      background-size: 100% 100%;
      padding: 30px 100px;
      box-sizing: border-box;
      p {
        color: #eee;
        font-size: 20px;
        text-align: center;
        margin: 15px auto;
      }
      input {
        background: none;
        border: 1px solid rgb(51, 146, 255);
        border-radius: 15px;
        height: 30px;
        outline: none;
        color: #eee;
        line-height: 30px;
        font-size: 16px;
        text-align: center;
        margin-bottom: 15px;
      }
      .btn {
        margin: auto;
        width: 90px;
        height: 36px;
        background: url("~@/assets/roll/btn.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .bg {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 9%;
      z-index: 90;
      background-color: rgba(0, 0, 0, 0.726);
    }
    .t-center {
      text-align: center;
    }
    .phone {
      .closes {
        color: #37d8ff;
        align-items: center;
        img {
          width: 16px;
          height: 17px;
        }
      }
      .rollmain {
        height: 665px;
        overflow: auto;
        margin-top: 15px;
        .homeabout {
          width: 180px;
          height: 40px;
          margin: auto;
          text-align: center;
          line-height: 40px;
          font-size: 25px;
          color: white;
          background: url("~@/assets/roll/add.png") no-repeat;
          background-size: 100% 100%;
        }
        .info {
          .time {
            margin-top: 10px;
            text-align: center;
            span {
              font-size: 32px;
              font-weight: bold;
              color: #ffffc0;
            }
            img {
              margin: 0 10px;
            }
          }
          .name {
            font-size: 40px;
            text-align: center;
            color: #62bce5;
            margin-top: 10px;
            margin-bottom: 10px;
          }
          .rollInfo {
            text-align: center;
            margin-bottom: 10px;
          }
        }
        .add {
          width: 90%;
          height: 40px;
          margin: auto;
          font-size: 18px;
          margin-bottom: 20px;
          color: #fff;
          line-height: 30px;
          text-align: center;
          background: url("~@/assets/roll/phone/join.png") no-repeat;
          background-size: 100% 100%;
        }
        .title {
          margin-top: 10px;
          justify-content: space-evenly;
          div {
            color: white;
          }
          .activediv {
            font-weight: bold;
            color: #fff000;
          }
        }
        .allnum {
          margin-top: 15px;
          color: white;
          justify-content: space-between;
          span {
            color: #fcff00;
          }
        }
        .gifts {
          margin: auto;
          width: 100%;
          box-sizing: border-box;
          padding: 20px;
          flex-wrap: wrap;
          overflow: auto;
          justify-content: space-around;
          .giftItem {
            width: 100px;
            height: 130px;
            background: url("~@/assets/roll/gifts.png") no-repeat;
            background-size: 100% 100%;
            padding: 12px;
            box-sizing: border-box;
            .g-title {
              margin: 4px 0;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              // 没置超出部分显示省略号
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
            }
            .img {
              margin-top: 15px;
              img {
                width: 64px;
                height: 30px;
              }
            }
            .w-money {
              width: 17px;
              height: 17px;
              background: url("~@/assets/images/shopping/bean.png") no-repeat;
              background-size: 100% 100%;
            }
            .priceItem {
              color: #fff000;
              font-weight: bold;
            }
          }
        }
        .headbox {
          flex-wrap: wrap;
          .peopleIiem {
            margin: 10px;
            img {
              width: 45px;
              height: 45px;
              border-radius: 50%;
            }
          }
        }
      }
      .activetitle {
        color: #fcff00 !important;
        font-weight: bold;
      }
    }
  }
}
</style>
